import { useState } from "react";
import { usePathname } from "next/navigation";
import dynamic from "next/dynamic";
import {
  Badge,
  Box,
  Button,
  Dialog,
  Divider,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  AccountCircle as AccountCircleIcon,
  Article,
  // AttachMoney,
  BugReport as BugReportIcon,
  Face,
  Home,
  Hub,
  Logout,
  ManageAccounts,
  Menu as MenuIcon,
  Reviews as ReviewsIcon,
  School as SchoolIcon,
  Science,
  SmartDisplay,
  Style,
} from "@mui/icons-material";
// import CustomLink from "../../util/CustomLink";
const BookModal = dynamic(() => import("../BookModal"));
const SignupModal = dynamic(() => import("../../util/SignupModal"));
const BrainVisualizerIntro = dynamic(() => import("../BrainVisualizerIntro"));
const NavbarFeedbackSection = dynamic(() => import("./NavbarFeedbackSection"));

const NavbarMainNav = ({ auth, linkClick, handleSignOut, path, setError }) => {
  const pathname = usePathname() || "";
  const [signupModalOpen, setSignupModalOpen] = useState(false);
  const [menuState, setMenuState] = useState({
    anchorEl: null,
    menuId: null,
  });
  const [feedbackTypeOpen, setFeedbackTypeOpen] = useState("");
  const [tutorialOpen, setTutorialOpen] = useState(false);
  const [demosOpen, setDemosOpen] = useState(false);
  const [bVIntroOpen, setbVIntroOpen] = useState(false);

  const handleOpenMenu = (event, menuId) => {
    event.stopPropagation();
    // Store clicked element (to anchor the menu to) and the menu id so we can tell which menu is open
    setMenuState({
      anchorEl: event?.currentTarget,
      menuId,
    });
  };

  const handleCloseMenu = () => {
    setMenuState({ anchorEl: null, menuId: null });
  };

  const handleTutorialClose = () => {
    setTutorialOpen(false);
  };

  const handleDemosClose = () => {
    setDemosOpen(false);
  };

  const iconStyle = { color: "accents.main", mr: "7px" };

  const menuItems = [
    {
      label: "Home",
      icon: <Home sx={iconStyle} />,
      path: "/",
      onClick: () => linkClick("/"),
    },
    path.startsWith("/brain-visualizer") && {
      label: "Quick Start",
      icon: <Style sx={iconStyle} />,
      onClick: () => setbVIntroOpen(true),
    },
    {
      label: "Tutorials",
      icon: <SchoolIcon sx={iconStyle} />,
      path: "/book/tutorials",
      onClick: () => setTutorialOpen(true),
    },
    {
      label: "Demos",
      icon: <SmartDisplay sx={iconStyle} />,
      path: "/book/demos",
      onClick: () => setDemosOpen(true),
    },
    {
      label: "Hub",
      icon: <Hub sx={iconStyle} />,
      path: "/hub",
      onClick: () => linkClick("/hub"),
    },
    {
      label: "Blog",
      icon: <Article sx={iconStyle} />,
      path: "/blog",
      onClick: () => linkClick("/blog"),
    },
    {
      label: "Feedback",
      icon: <ReviewsIcon sx={iconStyle} />,
      onClick: () => setFeedbackTypeOpen("thoughts"),
    },
    {
      label: "Bug Report",
      icon: <BugReportIcon sx={iconStyle} />,
      onClick: () => setFeedbackTypeOpen("issues"),
    },
    /* {!auth.user?.roles?.includes("ultimate-user") && (
        <MenuItem component={Link} to="/pricing" sx={{ color: "primary.main" }} >
          <ElectricBolt sx={{ color: "primary.main", mr: "3px" }} />
          {auth.user?.roles?.includes("pro-user") ? "Try Ultimate" : "Try Pro"}
        </MenuItem>
    )} */
  ];

  return (
    <Hidden implementation="css">
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {!auth.user ? (
          <Button
            onClick={() => setSignupModalOpen(true)}
            type="mui"
            color="inherit"
            size="small"
            variant="outlined"
          >
            {pathname.includes("embodiment") ? "Sign Up" : "Sign In"}
          </Button>
        ) : (
          <>
            {/* Profile icon */}
            <IconButton
              color="inherit"
              aria-label="Account"
              aria-controls="account-menu"
              aria-haspopup="true"
              onClick={(event) => {
                handleOpenMenu(event, "account-menu");
              }}
              style={{ borderRadius: "5px", padding: "5px" }}
            >
              <Badge
                color="primary"
                variant="standard"
                badgeContent={
                  auth.user?.roles?.includes("pro-user") ? "PRO" : "ULT"
                }
                invisible={
                  !auth.user?.roles?.includes("pro-user") &&
                  !auth.user?.roles?.includes("ultimate-user")
                }
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                sx={{
                  ".MuiBadge-badge": {
                    fontSize: "0.5rem",
                    fontWeight: "600",
                    height: "10px",
                    minWidth: "3px",
                    bottom: "1.5px",
                    right: "3px",
                    backgroundColor: auth.user?.roles?.includes("pro-user")
                      ? "primary.main"
                      : "gold.main",
                  },
                }}
              >
                <AccountCircleIcon title="account" />
              </Badge>
            </IconButton>

            {/* Profile dropdown */}
            <Menu
              id="account-menu"
              open={menuState?.menuId === "account-menu"}
              anchorEl={menuState?.anchorEl}
              onClick={handleCloseMenu}
              onClose={handleCloseMenu}
              keepMounted={true}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              {[
                {
                  path: "/settings/profile",
                  label: "Profile",
                  icon: Face,
                },
                {
                  path: "/settings/account",
                  label: "Account",
                  icon: ManageAccounts,
                },
                {
                  path: "/settings/genomes",
                  label: "My Genomes",
                  icon: Science,
                },
                // {
                //   path: "/settings/purchases",
                //   label: "Purchases",
                //   icon: AttachMoney,
                // },
              ].map(({ path, label, icon: Icon }) => (
                <MenuItem
                  key={path}
                  onClick={() => linkClick(path)}
                  disabled={pathname === path}
                >
                  <Icon sx={{ color: "accents.main", mr: "7px" }} />
                  {label}
                </MenuItem>
              ))}

              <Divider />

              <MenuItem onClick={handleSignOut}>
                <Logout sx={{ color: "accents.main", mr: "7px" }} />
                Sign Out
              </MenuItem>
            </Menu>
          </>
        )}

        {/* Hamburger main menu icon */}
        <IconButton
          aria-label="Main Menu"
          aria-controls="main-menu"
          aria-haspopup="true"
          onClick={(event) => {
            handleOpenMenu(event, "main-menu");
          }}
        >
          <MenuIcon />
        </IconButton>

        {/* Main menu dropdown */}
        <Menu
          id="main-menu"
          open={menuState?.menuId === "main-menu"}
          anchorEl={menuState?.anchorEl}
          onClick={handleCloseMenu}
          onClose={handleCloseMenu}
          // keepMounted={true}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {menuItems.map((item, index) => {
            if (!item) return null;
            return (
              <MenuItem
                key={index}
                onClick={item.onClick}
                disabled={pathname === item.path}
              >
                {item.icon}
                {item.label}
              </MenuItem>
            );
          })}
        </Menu>

        {/* {!path.startsWith("/brain-visualizer") && (
        <IconButton color="inherit" onClick={darkMode.toggle} style={{ opacity: 0.6 }} >
            {darkMode.value && <NightsStayIcon />}
            {!darkMode.value && <WbSunnyIcon />}
        </IconButton> )} */}

        {/* Feedback modal */}
        <Dialog
          open={feedbackTypeOpen}
          onClose={() => {
            setFeedbackTypeOpen("");
          }}
        >
          <NavbarFeedbackSection
            setFeedbackOpen={() => setFeedbackTypeOpen("")}
            starterType={feedbackTypeOpen}
          />
        </Dialog>

        {/* Quick Start modal */}
        {bVIntroOpen && (
          <BrainVisualizerIntro
            dialogOpen={bVIntroOpen}
            setDialogOpen={setbVIntroOpen}
            userOpened={true}
          />
        )}

        {/* Tutorial modal */}
        <Dialog
          maxWidth={false}
          open={tutorialOpen}
          onClose={handleTutorialClose}
          sx={{
            "& .MuiDialog-paper": {
              width: "100%",
              maxWidth: "1500px",
              height: "100%",
              overflow: "hidden",
            },
          }}
        >
          <BookModal
            type="tutorials"
            open={tutorialOpen}
            setOpen={setTutorialOpen}
            onClose={handleTutorialClose}
            setParentError={setError}
            isModal={true}
          />
        </Dialog>

        {/* Demos modal */}
        {demosOpen && (
          <Dialog
            maxWidth={false}
            open={demosOpen}
            onClose={handleDemosClose}
            sx={{
              "& .MuiDialog-paper": {
                width: "100%",
                maxWidth: "1500px",
                height: "100%",
                overflow: "hidden",
              },
            }}
          >
            <BookModal
              type="demos"
              open={demosOpen}
              setOpen={setDemosOpen}
              onClose={handleDemosClose}
              setParentError={setError}
              isModal={true}
              // fetchData={getDemosData}
            />
          </Dialog>
        )}
      </Box>

      {/* Signup modal */}
      {signupModalOpen && (
        <SignupModal
          open={signupModalOpen}
          setOpen={setSignupModalOpen}
          authType={pathname.includes("embodiment") ? "signup" : "signin"}
        />
      )}
    </Hidden>
  );
};

export default NavbarMainNav;
