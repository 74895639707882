// This passes along query params for analytics, etc.
import { useSearchParams } from "next/navigation";
import { Link as MuiLink } from "@mui/material";
import { useAuth } from "./auth";
import Link from "next/link";

function CustomLink({ href, children, type = "router", ...props }) {
  const auth = useAuth();
  const searchParams = useSearchParams();

  // Add query params to the href
  let finalHref = href || "#";
  if (!auth.user && href) {
    if (searchParams) {
      const filteredParams = new URLSearchParams(searchParams);
      filteredParams.delete("page"); // Remove 'page' param

      if (filteredParams.toString()) {
        const delimiter = href.includes("?") ? "&" : "?"; // only add ? if it's not already present
        finalHref += delimiter + filteredParams.toString(); // add filtered query params
      }
    }
  }

  // Render the link depending on props type
  switch (type) {
    case "mui":
      return (
        <MuiLink href={finalHref} {...props}>
          {children}
        </MuiLink>
      );
    case "router":
      return (
        <Link href={finalHref} {...props}>
          {children}
        </Link>
      );
    default:
      return (
        <a href={finalHref} {...props}>
          {children}
        </a>
      );
  }
}

export default CustomLink;
