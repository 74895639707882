"use client";

// Generic dialog component. Children represents custom actions
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

const CustomDialog = ({
  header,
  text,
  richText,
  buttonText,
  isOpen,
  handleClose,
  confirmAction,
  cancelAction,
  closeButton = true,
  actionsChildren,
  maxWidth = "sm",
  isDisabled = false,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function callConfirm() {
      try {
        await confirmAction();
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    }

    loading && callConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
      fullWidth
    >
      {/* Title & close button */}
      <DialogTitle id="alert-dialog-title">
        <Box
          sx={{
            display: "flex",
            justifyContent: header ? "space-between" : "flex-end",
            alignItems: "center",
            gap: 1,
          }}
        >
          {header ? (
            <Typography
              variant="h6"
              sx={{ fontSize: { xs: "1.2rem", md: "1.4rem" } }}
            >
              {header}
            </Typography>
          ) : null}
          {closeButton && (
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </DialogTitle>

      {/* Description text */}
      {(text || richText) && (
        <DialogContent sx={{ pt: 1 }}>
          {text && (
            <DialogContentText id="alert-dialog-description">
              {text}
            </DialogContentText>
          )}
          {richText}
        </DialogContent>
      )}

      {/* Buttons */}
      {(cancelAction || confirmAction || actionsChildren) && (
        <DialogActions sx={{ padding: "8px 20px 16px" }}>
          {actionsChildren}
          {(cancelAction || confirmAction) && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent:
                  cancelAction && confirmAction ? "space-between" : "flex-end",
              }}
            >
              {cancelAction && (
                <Button
                  onClick={cancelAction}
                  variant="outlined"
                  disabled={loading}
                >
                  Cancel
                </Button>
              )}
              {confirmAction && (
                <Button
                  onClick={() => {
                    setLoading(true);
                  }}
                  variant="contained"
                  disabled={loading || isDisabled}
                  autoFocus
                >
                  {loading ? (
                    <CircularProgress size={18} />
                  ) : buttonText ? (
                    buttonText
                  ) : (
                    "Proceed"
                  )}
                </Button>
              )}
            </Box>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CustomDialog;
