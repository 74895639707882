import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/nrs-portal/nrs-portal/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/nrs-portal/nrs-portal/node_modules/@mui/material/CircularProgress/CircularProgress.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/nrs-portal/nrs-portal/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/nrs-portal/nrs-portal/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/nrs-portal/nrs-portal/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/home/runner/work/nrs-portal/nrs-portal/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/runner/work/nrs-portal/nrs-portal/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/nrs-portal/nrs-portal/src/components/AppLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/nrs-portal/nrs-portal/src/components/ErrorBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/nrs-portal/nrs-portal/src/components/Footer.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/nrs-portal/nrs-portal/src/components/navbar/NavbarWrapper.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/nrs-portal/nrs-portal/src/components/Notifications.tsx");
