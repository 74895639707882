import Image from "next/image";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Box, Button, Container, Typography } from "@mui/material";

function ErrorTopLevel() {
  return (
    <Section
    //   bgcolor={props.bgcolor}
    //   size={props.size}
    //   bgImage={props.bgImage}
    //   bgImageOpacity={props.bgImageOpacity}
    >
      <Container
        maxWidth="md"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SectionHeader
          title="Error Occurred"
          textAlign="center"
          sx={{
            backgroundColor: "rgb(16, 18, 20) !important",
            color: "rgb(255, 255, 255) !important",
          }}
        />
        <Box
          marginTop="10px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ color: "rgb(255, 255, 255)" }}
        >
          <Typography>Sorry, an unknown error occurred!</Typography>
          <Typography>Please reload and/or go back.</Typography>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              maxWidth: "400px",
              height: "auto",
            }}
          >
            <Image
              src="/img/robot-bewildered-transparent.png"
              alt="bewildered robot"
              width={0}
              height={0}
              sizes="100vh"
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
          <Button variant="contained" href="/" sx={{ mt: 2 }}>
            Go Home
          </Button>
        </Box>
      </Container>
    </Section>
  );
}

export default ErrorTopLevel;
