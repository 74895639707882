import { Link as LinkMui } from "@mui/material";
import {
  GitHub as GitHubIcon,
  LinkedIn as LinkedInIcon,
  X as XIcon,
  YouTube as YouTubeIcon,
} from "@mui/icons-material";
import Image from "next/image";

const SocialLinks = () => {
  // const iconsColor = darkMode.value ? "white" : "black";
  // const iconsColor = "white";
  const links = [
    {
      Icon: YouTubeIcon,
      url: "https://www.youtube.com/@Neuraville",
      type: "mui",
      alt: "Youtube",
    },
    {
      Icon: LinkedInIcon,
      url: "https://www.linkedin.com/company/neuraville/",
      type: "mui",
      alt: "Linked In",
    },
    { Icon: XIcon, url: "https://x.com/neuraville", type: "mui" },
    {
      Icon: GitHubIcon,
      url: "https://github.com/feagi/feagi",
      type: "mui",
      alt: "X",
    },
    {
      Icon: "/img/discord-white.png",
      url: "https://discord.gg/PTVC8fyGN8",
      type: "image",
      alt: "Discord",
    },
  ];

  return (
    <>
      {links.map(({ Icon, url, type, alt }, index) => (
        <LinkMui
          key={index}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`Visit Neuraville's ${alt}`}
          sx={{
            color: "inherit",
            lineHeight: 1,
          }}
        >
          {type === "mui" && <Icon fontSize="small" color="white" />}
          {type === "image" && (
            <Image
              src={Icon}
              alt={alt}
              width={20}
              height={20}
              style={{ objectFit: "contain" }}
            />
          )}
        </LinkMui>
      ))}
    </>
  );
};

export default SocialLinks;
